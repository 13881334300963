import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usersSelector } from '../../store/slices/users/usersSlice'

const Admin = () => {
    const [usersData, setUsersData] = useState<any[]>([])
    const [getUsersData, setGetUsersData] = useState<boolean>(true)
    const { adminUserToken } = useSelector(usersSelector)

    useEffect(() => {
        if (getUsersData) {
            axios.get('https://bazaaar.deals/api/user', {
                headers: {
                    Authorization: `Bearer ${adminUserToken}`
                }
            }).then(res => {
                setUsersData(res.data.users?.map((el: any) => ({
                    id: el._id,
                    name: el.firstName,
                    lastName: el.lastName,
                    email: el.email,
                    password: el.password,
                    togglePass: false
                })));
                setGetUsersData(false);
            }).catch(error => {
                setGetUsersData(false);
            });
        }
    }, [getUsersData]);


    return (
        <section className='admin'>
            <div className="admin__container _container">
                <div className="contracts-table">
                    <table>
                        <thead>
                            <tr>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Last name</td>
                                <td>Email</td>
                                {/* <td>Password</td> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                usersData.length ? (
                                    usersData.map(el => (
                                        <tr key={el.id}>
                                            <td>{el.id}</td>
                                            <td>{el.name}</td>
                                            <td>{el.lastName}</td>
                                            <td>{el.email}</td>
                                            {/* <td>{
                                                el.togglePass ? el.password : <button>***</button>
                                            }</td> */}
                                        </tr>
                                    ))
                                ) : ''
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default Admin
