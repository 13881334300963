import React from 'react'
import Dashboard from '../Dashboard/Dashboard'
import StaticsOverivew from '../StaticsOverivew/StaticsOverivew'

function DashboardPage() {
  return (
    <>
      <Dashboard />
    </>
  )
}

export default DashboardPage
