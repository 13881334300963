import React from 'react'
import Login from '../Login/Login'
import Subscribe from '../Subscribe/Subscribe'

function LoginPage() {
  return (
    <>
      <Login />
    </>
  )
}

export default LoginPage
