import Registration from '../Registration/Registration'

function RegistrationPage() {
  return (
    <>
      <Registration />
    </>
  )
}

export default RegistrationPage
