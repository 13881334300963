import React from 'react'
import Dashboard from '../Dashboard/Dashboard'
import Product from '../Product/Product'

function ProductPage() {
  return (
    <>
      <Product />
    </>
  )
}

export default ProductPage
